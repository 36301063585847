.panel {
	width: 100%;
	margin: $default-spacing/2 0px $default-spacing*1.5 0px;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.8);
    background-color: #181818;

	.panel-body {
		padding: $default-spacing*2;
	}
	.panel-heading {
		padding: $default-spacing*2 $default-spacing*2 0px $default-spacing*2;
	}

	.panel-heading + .panel-body {
		padding-top: $default-spacing;
	}

	h1.panel-title,
	h2.panel-title,
	h3.panel-title,
	h4.panel-title,
	h5.panel-title,
	h6.panel-title {
		font-size: $base-font-size*2;
		margin: 0px;
	}
}
