@import "vars";

button,
.btn,
a.btn {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	background: $ui-button-color;
	color: $ui-button-text-color;
	border: 1px solid $ui-button-hover-color;
	border-radius: 1px;

	text-transform: uppercase;
	font-size: $ui-font-size*1.2;
	line-height: 0.9em;
	font-weight: 600;
	padding: $default-spacing/3*2 $default-spacing*2;
	cursor: pointer;
	display: inline-block;

	&:hover {
		background: $ui-button-hover-color;
		border-color: $ui-button-hover-color;
	}

	&.btn-xs {
		font-size: $ui-font-size*0.8;
		padding: $default-spacing/3 $default-spacing;
	}

	&.btn-upload {
		position: relative;
		overflow: hidden;

		input {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			padding: 0;
			font-size: 20px;
			cursor: pointer;
			opacity: 0;
			filter: alpha(opacity=0);
		}
	}
}