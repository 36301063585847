.img-responsive {
    width: auto;
    height: auto;
    max-width: 100%;
}

.img-thumbnail {
    width: auto;
    height: auto;
    max-width: 100%;

    border: 1px solid #ccc;
    border-radius: 2px;
    padding: $default-spacing/2;
}

.align-left {
	float: left;
	text-align: left;
}

.align-right {
	float: right;
	text-align: right;
}

body {
    .beta-notice {
        position: fixed;
        bottom: -40px;
        left: 0px;
        width: 100%;
        color: #222;
        padding-top: 40px;
        transition: bottom .5s;
        height: 80px;

        .badge {
            background: $status-warning-color;
            position: absolute;
            top: 0px;
            left: 50px;
            padding: 0px 10px;
            border: 1px solid darken($status-warning-color, 20%);
            border-top-right-radius: 2px;
            border-top-left-radius: 2px;
            border-bottom: none;
            overflow: hidden;
            cursor: pointer;

            span {
                line-height: 40px;
                float: left;
            }

            .ionicon {
                font-size: 24px;
                margin: 0px $default-spacing 0px 0px;
            }
        }

        .content {
            background: $status-warning-color;
            line-height: 40px;
            padding: 0px 10px;

            a {
                color: #222;

                &:hover {
                    color: lighten(#222, 20%);
                }
            }
        }
    }

    &.show-beta-notice {
        .beta-notice {
            bottom: 0px;
        }
    }
}