@import "base/reset";
@import "base/grid";
@import "base/utils";

@import "base/typography";
@import "base/panel";
@import "base/alert";
@import "base/form";
@import "base/button";
@import "base/table";
@import "base/user-panel";

body {
	background: #111 url('/assets/images/darknoise.png');
}

.content {
	min-height: calc(100vh - 165px);
	padding: $default-spacing*2 0px;
}

.header {
	padding: $default-spacing*0.75;
    //background-color: rgba(0,0,0,0.4);
	
	.logo {
		width: auto;
		height: 90px;
		margin-left: 50%;
		transform: translate(-50%, 0px);
	}

}
.user-panel {
	right: 100px;
}

.navigation {
	padding: $default-spacing*1.25 $default-spacing*2;
	background: #2245c9;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
	overflow: hidden;

	ul {
		li {
			display: block;
			float: left;
			position: relative;
			font-size: $base-font-size*1.25;
			line-height: $base-font-size;
			padding: 0px $default-spacing*2 0px 0px;
			font-family: $headline-font-family;
			font-weight: 300;
			text-transform: uppercase;

			a {
				color: #fff;

				&:after {
					content: "›";
					font-size: $base-font-size*2;
					position: absolute;
					right: 10px;
					top: -2px;
				}
			}

			&:last-child {
				padding: 0px;

				a:after {
					content: "";
				}
			}
		}
	}
}

.intro {
	h1 {
		font-family: $headline-font-family;
		font-size: $base-font-size*4;
		line-height: $base-font-size*4;
		text-align: center;
		margin-bottom: $default-spacing * 5;
	}
}


@media screen and (min-width: $screen-md-min) {
	.intro {
		padding: $default-spacing*2;
		width: 80%;
		margin: 0 auto;

		h1 {
			font-size: $base-font-size*3;
			line-height: $base-font-size*4;
			font-weight: 300;
		}
	}
}

.footer {
    background-color: #181818;
    min-height: 120px;
}
