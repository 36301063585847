@import "vars";

body, p {
	font-family: $text-font-family;
	font-size: $text-font-size;
	font-weight: 400;
	color: $text-color;
	line-height: $text-font-size*1.4;

	small {
		font-size: $text-font-size * 0.8;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $headline-font-family;	
	margin: $default-spacing/2 0px $default-spacing 0px;
	font-weight: 400;
	line-height: 1em;
}

h1, .h1 {
	font-size: $h1-font-size;
}

h2, .h2 {
	font-size: $h2-font-size;
}

h3, .h3 {
	font-size: $h3-font-size;
}

h4, .h4 {
	font-size: $h4-font-size;
}

h5, .h5 {
	font-size: $h5-font-size;
}

h6, .h6 {
	font-size: $h6-font-size;
}

a, a:active, a:link, .link {
	color: $link-color;
	text-decoration: none;
}

a:hover {
	color: $link-color-hover;
	cursor: pointer;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

ul.list {
	list-style: disc outside none;
	padding: 0px 0px 0px 20px;
	margin: 0px 0px $default-spacing 0px;

	li {
		margin: 0px 0px $default-spacing/2 0px;
	}
}

p, ul, pre, ol {
	margin: 0px 0px $default-spacing 0px;
}

strong, b {
	font-weight: 700;
}