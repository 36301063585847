@import "vars";

.alert {
	padding: $default-padding;
	border: 1px solid $status-default-color;
	margin: 0px 0px $default-spacing 0px;
	color: $status-default-color;

	&.alert-success {
		color: $status-success-color;
		border-color: $status-success-color;
	}

	&.alert-error {
		color: $status-error-color;
		border-color: $status-error-color;
	}

	&.alert-warning {
		color: $status-warning-color;
		border-color: $status-warning-color;
	}
}

.label {
	&.label-warning {
		color: $status-warning-color;
	}
	
	&.label-error {
		color: $status-error-color;
	}
	
	&.label-success {
		color: $status-success-color;
	}
}