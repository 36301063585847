@import "vars";

form,
.form {
	.alert {
		display: none;
	}

	&.status-success {
		.alert-success {
			display: block;
		}
	}

	&.status-error {
		.alert-error {
			display: block;
		}
	}

}

.form-group {
	margin: 0px 0px $default-spacing 0px;

	.hide-label {
		label {
			display: none;
		}
	}

	&.placeholder {
		position: relative;
		padding: $default-spacing*1.5 0px 0px 0px;

		label {
			position: absolute;
			bottom: 5px;
			transition: .2s;
			font-weight: 400;
		}

		.form-control:focus + label,
		.form-control:not(:placeholder-shown) + label  {
			bottom: 25px;
			font-size: $base-font-size * 0.9;
		}
	}
}


.button-group {
	padding: $default-spacing 0px 0px 0px;
	text-align: right;

	.btn {
		display: inline-block;
	}
}

label {
	display: block;
	font-weight: 600;
	margin: 0px 0px $default-spacing/2 0px;
}

.form-control {
	display: block;
	border: none;
	border-bottom: 1px solid #ccc;
	box-shadow: none;
	outline: none;

	font-family: $ui-font-family;
	font-size: $ui-font-size;
	line-height: $base-font-size*2;
	height: $base-font-size*2;
	padding: 2px 6px;

	width: 100%;
	background: transparent;
	color: $ui-font-color;
}

textarea.form-control {
	border: 1px solid #ccc;
}


.checkbox {
	overflow: hidden;
	
	input {
		float: left;
		margin: $default-spacing/4 $default-spacing/2 0px 0px;
	}

	label {
		float: left;
		margin: 0px;
		font-weight: normal;
	}
}