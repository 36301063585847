
@import "vars";

table,
.table {

	width: 100%;

	&.table-striped {
		tr:nth-child(odd) {
			background: #111;
		}

		thead {
			tr:nth-child(odd) {
				background: transparent;
			}
		}
	}



	th {
		font-weight: bold;
		border-bottom: 1px solid #ccc;
	}

	td, th {
		padding: $default-spacing/2;
	
		&.align-left {
			float: none;
			text-align: left;
		}

		&.align-right {
			float: none;
			text-align: right;
		}
		
		&.align-center {
			float: none;
			text-align: center;
		}
	}
}